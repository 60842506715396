import { Trans, useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation(['home', 'common']);

  return (
    <footer className="mt-16 md:mt-28 w-full pt-16 pb-8 bg-green-200 px-8 sm:px-12 md:px-40 lg:px-64">
      <div className="flex flex-col gap-2 md:gap-0 items-start justify-between">
        <h2 className="font-serif">{t('home:footer.celebrate')}</h2>
        <small className="text-left">info@kumbhsukrit.com / +91 8860092457</small>
      </div>
      <div className="bg-black/20 w-full my-6 h-px" />
      <div className="flex flex-col md:flex-row items-start justify-between">
        <p className="max-w-80">
          <Trans i18nKey="home:footer.sw">
            A product of{' '}
            <a className="underline text-blue-500" href="https://sukritwellness.com">
              Sukrit Wellness Tours
            </a>
            , affiliated with the Ministry of Tourism and Incredible India, we have been
            hosting Kumbh for 10+ years. Join us in 2025.
          </Trans>
        </p>
      </div>
      <div className="border-b border-dashed border-b-black/10 w-full mt-6 h-px" />
      <div className="flex flex-col xs:flex-row xs:justify-between">
        <small className="block mt-6 text-xs font-sans font-light">
          © All Rights Reserved 2024, kumbh sukrit.
        </small>
        <small className="block mt-3 md:mt-6 text-xs font-sans font-light">
          {t('common:product-of')}{' '}
          <a href="https://sukritwellness.com" className="text-blue-500 underline">
            Sukrit Wellness Tours
          </a>{' '}
          Pvt Ltd.
        </small>
      </div>
    </footer>
  );
};
